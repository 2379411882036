import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Flex, ImgHover, ImgStyled, StyledLink, TitleHover } from "../elements"
import website from "../../config/website"

const GridWithHero = styled.div`
  display: grid;
  grid-gap: 5px 5px;
  ${props => props.theme.mediaQueries.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${props => props.theme.mediaQueries.md} {
    grid-template-columns: repeat(5, 1fr);
  }
  ${props => props.theme.mediaQueries.lg} {
    grid-template-columns: repeat(6, 1fr);
  }
  ${props => props.theme.mediaQueries.xl} {
    grid-template-columns: repeat(6, 1fr);
  }
  &:before {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  & > *:first-child {
    grid-gap: 0;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`

const designQuery = graphql`
  query {
    prismicDomain(lang: { eq: "en-us" }, uid: { eq: "design" }) {
      uid
      data {
        title {
          text
          html
        }
        image_mosaic_rollover {
          url
          alt
          fluid(maxWidth: 600, maxHeight: 600) {
            ...GatsbyPrismicImageFluid
          }
        }
        image_mosaic {
          url
          alt
          fluid(maxWidth: 600, maxHeight: 600) {
            ...GatsbyPrismicImageFluid
          }
        }
        list_item {
          image {
            url
            alt
            fluid(maxWidth: 600, maxHeight: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
          title_item {
            text
          }
          media {
            link_type
            __typename
            type
            url
            target
            isBroken
            size
            document {
              ... on PrismicProject {
                uid
                id
                data {
                  title {
                    text
                  }
                  image_mosaic {
                    url
                    alt
                    fluid(maxWidth: 600, maxHeight: 600) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
              ... on PrismicDomain {
                uid
                data {
                  title {
                    text
                  }
                  image_mosaic {
                    url
                    alt
                    fluid(maxWidth: 600, maxHeight: 600) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  image_mosaic_rollover {
                    url
                    alt
                    fluid(maxWidth: 600, maxHeight: 600) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const DesignPage = ({ location, pageContext: { breadcrumb } }) => {
  return (
    <Layout mt="18px" location={location} breadcrumb={breadcrumb}>
      <SEO title={`Design Studio | ${website.titleAlt}`} pathname="/design" />
      <StaticQuery
        query={designQuery}
        render={data => (
          <GridWithHero>
            {data.prismicDomain?.data?.list_item.map(({ media }, index) => {
              if (media.document) {
                const isRolloverImage = media.document.data
                  .image_mosaic_rollover
                  ? true
                  : false
                if (media.document?.data?.image_mosaic)
                  return (
                    <StyledLink
                      to={`${location.pathname}/${media.document.uid}`}
                      key={`${index}-img`}
                      isRolloverImage={isRolloverImage}
                      state={{
                        previousProjectPath:
                          index - 1 > -1
                            ? `/art/${
                                data.prismicDomain.data.list_item[index - 1].media
                                  .document.uid
                              }`
                            : undefined,
                        nextProjectPath:
                          index + 1 < data.prismicDomain.data.list_item.length
                            ? `/art/${
                                data.prismicDomain.data.list_item[index + 1].media
                                  .document.uid
                              }`
                            : undefined,
                      }}
                      css="position:relative;"
                    >
                      <ImgStyled
                        fluid={media.document.data.image_mosaic.fluid}
                        height="100%"
                        width="100%"
                        alt={media.document.data.image_mosaic.alt}
                      />
                      {isRolloverImage && (
                        <ImgHover
                          fluid={
                            media.document.data.image_mosaic_rollover.fluid
                          }
                          height="100%"
                          width="100%"
                          alt={media.document.data.image_mosaic_rollover.alt}
                        />
                      )}
                      {!isRolloverImage && (
                        <TitleHover>{media.document.data.title.text}</TitleHover>
                      )}
                    </StyledLink>
                  )
                if (media.document?.data?.title)
                  return (
                    <Flex
                      bg="white"
                      color="black"
                      alignItems="center"
                      justifyContent="center"
                      key={`${index}-title`}
                    >
                      <Link
                        to={`${location.pathname}/${media.document.uid}`}
                        state={{
                          previousProjectPath:
                            index - 1 > -1
                              ? `/art/${
                                  data.prismicDomain.data.list_item[index - 1].media
                                    .document.uid
                                }`
                              : undefined,
                          nextProjectPath:
                            index + 1 < data.prismic.domain.list_item.length
                              ? `/art/${
                                  data.prismicDomain.data.list_item[index + 1].media
                                    .document.uid
                                }`
                              : undefined,
                        }}
                      >
                        {media.document.data.title.text}
                      </Link>
                    </Flex>
                  )
              }
              return null;
            })}
          </GridWithHero>
        )}
      />
    </Layout>
  )
}

export default DesignPage
